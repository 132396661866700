<template>
  <Image
    :src="countryFlag"
    :width="width ? width : -1"
    :height="height ? height : -1"
    :radius="`circle`"
    :alt="`${$t('country')} - ${country}`"
  />
</template>

<script lang="ts">
export default {
  name: 'global-country',
  props: {
    country: {
      type: String,
      default () {
        return ''
      }
    },
    width: {
      type: Number,
      default () {
        return 0
      }
    },
    height: {
      type: Number,
      default () {
        return 0
      }
    }
  },
  setup (props) {

    const countryFlag: any = ref(globals.getImage(`/flag/country/${props.country ? props.country.toLowerCase() : 'us'}.webp`))

    // 메모리 비우기
    function restore () {
      countryFlag.value = null
    }

    // 
    onBeforeUnmount (() => {
      restore()
    })

    return {
      countryFlag
    }
  }
}
</script>